<template>
  <div class="detailbox">
    <div v-if="fileType=='pdf'">
       <pdf-view :url = "fileUrl"/>
    </div>
    <div v-if="fileType=='html'">
      <slot v-bind:header="newsdeta">
        <div class="flexcent title">
          {{ newsdeta.title || newsdeta.name }}
        </div>
        <div class="flexcent time"  v-if="newsdeta.createdTime">
          发布时间：{{ newsdeta.createdTime | FormatDate('yyyy-MM-dd')}}
        </div>
      </slot>
      <div class="content"  >
        <html-frame  :url = "fileUrl" width="900px" />
          
        <!-- 
        <html-frame v-if="newsdeta.contentUrl"  :url = "newsdeta.contentUrl" width="900px"/>
        <html-frame v-else-if="newsdeta.fileUrl" :url = "newsdeta.fileUrl" width="900px"/> 
        -->
        <!-- <div v-else> 连接地址缺失，请联系管理员 </div>  -->
      </div>
    </div>
  </div>
</template>

<script>
import htmlFrame from "@/components/all/htmlFrame"; //引入尾部组件
import pdfView from "./pdfView";

export default {
  name: "detail",  
  components: {
    htmlFrame,
    pdfView,
  },

  props: {
    newsdeta: {
      typeof: [Object],
    },
    height: {
      typeof: [String],
      default: "100px",
    }
  },
  computed: {
    fileUrl() {
      if(this.newsdeta.contentUrl)
        return this.newsdeta.contentUrl
      if(this.newsdeta.fileUrl)
        return this.newsdeta.fileUrl
      console.log(" not set fileUrl ", this.newsdeta )  
      return null
    },
    fileType() {
      console.log(this.fileUrl)
      if(this.fileUrl) {
        if(this.fileUrl.endWith(".pdf"))
          return "pdf";
        if(this.fileUrl.endWith(".html"))
          return "html";
        else {
          console.log(" not support type " , this.fileUrl)
          return null;
        }  
      }
      console.log(" not set fileUrl  " , this.fileUrl)
      return null
    }
  },
  created() {
    // console.log(this.newsdeta);
  },
};
</script>


<style scoped lang="less">

.detailbox{
   
    .content {
        /deep/ p {
          font-size: 0.875rem;
          // margin:20px auto;
        }
    }
    .title {
      margin-top: 1.25rem;
      text-align: center;
      font-weight: 550;
      font-size: 1rem;
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ececec;
    }
  }


@media screen and (min-width: 1000px) {
  .title {
    height: 3.125rem;
    font-weight: 550;
    font-size: 1.5rem;
  }
  .time {
    height: 2rem;
    font-size: 0.875rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.0625rem solid #ececec;
  }
  // .content {
  //  border: 2px solid #f00;
  //   padding: 2.5rem 0.9375rem;
  //   /deep/ p {
  //     font-size: 0.875rem;
  //   }
  // }
}

@media screen and (max-width: 999px) {
  .contentbox{
    padding: 0 .9375rem;
    box-sizing: border-box;
    width: 100%;
    .content {
        /deep/ p {
          font-size: 0.875rem;
        }
    }
    .title {
      margin-top: 1.25rem;
      text-align: center;
      font-weight: 550;
      font-size: 1rem;
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ececec;
    }
  }
 

}
</style>
