<template>
    <div class="html-frame" :style="'opacity:'+opacity">
      <iframe id="hframe" :src="url"   @load="iframeLoad" frameborder="0" :style="'width:'+ width +';height:' + height "></iframe> 
    </div>
</template>

<script>

export default {
  name: 'HtmlFrame',
  props: {
    width:  {
      typeof: [String],
      default: "900px",
    },
    height:  {
      typeof: [String],
      default: "100px",
    },
    url: {
      typeof: [String],
      require: true,
    },
  },
   data() {
    return {
      opacity: 0,
    }
   },
  mounted() {
    const me = this
    const iframe = document.querySelector('#hframe')
    // 处理兼容行问题
    // if (iframe.attachEvent) {
    //   iframe.attachEvent('onload', function () {
    //     // iframe加载完毕以后执行操作
    //     // console.log('attachEvent iframe已加载完毕')
    //     this.style.height = this.contentWindow.document.documentElement.scrollHeight + 50+ "px"
    //     this.style.width = this.contentWindow.document.documentElement.scrollWidth + 50+ "px"
    //   })
    // } else {
      iframe.onload = function () {
        // iframe加载完毕以后执行操作
        console.log('onload iframe已加载完毕')
        console.log("  this.contentWindow.document.documentElement.scrollHeight  ",  this.contentWindow.document.documentElement.scrollHeight)
        this.style.height = this.contentWindow.document.documentElement.scrollHeight + 10+ "px"
        console.log("  this.style.height  ",  this.style.height)
        this.style.width = this.contentWindow.document.documentElement.scrollWidth + "px"
        const timeFunc = setInterval(() => {
          me.opacity = 1
          clearInterval(timeFunc)
        }, 100)
        
        // console.log("  this.style.width  ",  this.style.width)
        // console.log("  this.style.height  ",  this.style.height)
        // console.log("  this.contentWindow.document.documentElement.scrollHeight  ",  this.contentWindow.document.documentElement.scrollHeight)
      }
    // }    
    // console.log("  this.width "+this.width)
    // console.log( "  iframe.style.width " + iframe.style.width)  
  },
  methods: {
    // info(doc){
    //   console.log("   doc", doc)
    //   console.log("   scrollHeight", doc.documentElement.scrollHeight)
    //   console.log("   scrollWidth", doc.documentElement.scrollWidth)
    // },
    iframeLoad(){
      // console.log("  ============== iframeLoad ", item)
      // const iframe = document.querySelector('#hframe')
      //    console.log("   iframeh "+ iframe)
      //    console.log("  this.width "+this.width)
      //    console.log( "  iframe.style.width " + iframe.style.width)  


      //   console.log("  iframe.contentWindow.document.documentElement.scrollHeight  ",  iframe.contentWindow.document.documentElement.scrollHeight)
      //   this.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 1000+ "px"
      //   console.log("  iframe.style.height  ",  iframe.style.height)
      //   this.style.width = iframe.contentWindow.document.documentElement.scrollWidth + 20+ "px"

      //   console.log("  iframe.style.width  ",  iframe.style.width)
      //   console.log("  iframe.style.height  ",  iframe.style.height)
      //   console.log("  iframe.contentWindow.document.documentElement.scrollHeight  ",  iframe.contentWindow.document.documentElement.scrollHeight)

    }
  },
};
</script>

<style scoped lang="less">
  .html-frame {
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: center;
    // border: 3px solid  #0f0; 
  }

</style>
