<template>

        <html-detail :newsdeta = "patent"/>

</template>

<script>

import htmlDetail from "@/components/all/htmlDetail"; //引入尾部组件

export default {
  name: 'TrainDetail',
  components: {
    htmlDetail,

  },

  data() {
    return {   
      patent: {},
      id: '',
      title: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "培训",
          path: "/train",
          query: {},
        },
        {
          name: "详细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   patent id " ,   this.id)
    },
    getdata() {
      this.$api.getTrainDetail(this.id).then((res) => {
        this.patent = res.data;
        this.title = this.patent.title
        console.log("  get patent " , this.patent)
      });
    },
  },
};
</script>

<style scoped lang="less">


</style>
